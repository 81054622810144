import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from '@angular/core';
// import { ROUTESADMIN } from './vertical-menu-routes.config';
import {HROUTES} from '../horizontal-menu/navigation-routes.config';

import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {customAnimations} from '../animations/custom-animations';
import {DeviceDetectorService} from 'ngx-device-detector';
import {ConfigService} from '../services/config.service';
import {Subscription} from 'rxjs';
import {LayoutService} from '../services/layout.service';
import {User} from '../../models/entities/user.model';
import {KenekankoUtils} from '../../utils/kenekanko-utils';
import {RouteInfo} from './vertical-menu.metadata';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vertical-menu.component.html',
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  currentUser: User = new KenekankoUtils().getAppUser();


  ROUTESADMIN: RouteInfo[] = [
    {
      path: '/dashboard',
      title: 'Tableau de bord',
      icon: 'ft-home',
      class: '',
      badge: '',
      badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: []
    },

    {
      path: '',
      title: 'Alertes',
      icon: 'ft-bell',
      class: 'has-sub',
      badge: '2',
      badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
      isExternalLink: false,
      submenu: [
        {
          path: 'page/alerts-checks',
          title: 'Vérifications',
          icon: 'ft-check submenu-icon',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/page/corbeille',
          title: 'Poubelle',
          icon: 'ft-trash submenu-icon',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        {
          path: '/page/draft',
          title: 'Brouillon',
          icon: 'ft-edit submenu-icon',
          class: '',
          badge: '',
          badgeClass: '',
          isExternalLink: false,
          submenu: []
        },
        // {
        //   path: '/page/report',
        //   title: 'Rapport',
        //   icon: 'ft-edit submenu-icon',
        //   class: '',
        //   badge: '',
        //   badgeClass: '',
        //   isExternalLink: false,
        //   submenu: []
        // },


      ]
    },

    // {
    //   path: '/follow-management',
    //   title: 'Suivis',
    //   icon: 'ft-bar-chart',
    //   class: '',
    //   badge: '',
    //   badgeClass: '',
    //   isExternalLink: false,
    //   submenu: []
    // }
  ];

  adminRouteComplement: RouteInfo = {
    path: '',
    title: 'Paramétrage',
    icon: 'ft-settings',
    class: 'has-sub',
    badge: '8',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    submenu: [
      {
        path: '/account-management/users',
        title: 'Gestions Utilisateurs',
        icon: 'ft-edit submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/form-management',
        title: 'Gestion formulaire',
        icon: 'ft-edit submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/violation-type',
        title: 'Gestion type violation',
        icon: 'ft-edit submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/organisations',
        title: 'Organisations',
        icon: 'ft-home submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },

      {
        path: '/categories',
        title: 'Categories',
        icon: 'ft-grid submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/page/category-management',
        title: 'Catégorie Rapport',
        icon: 'ft-file submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/page/report-management',
        title: 'Documentations',
        icon: 'ft-file submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/page/localite',
        title: 'Localite',
        icon: 'ft-file submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/page/app-version-management',
        title: 'Version app mobile',
        icon: 'ft-phone submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
      {
        path: '/page/old-alerts',
        title: 'Alertes vocales',
        icon: 'ft-mic submenu-icon',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
      },
    ]
  };

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    if (!this.currentUser.organization) {
      // console.log('USER ADMIN', this.currentUser);
      this.ROUTESADMIN.push(this.adminRouteComplement);
      this.menuItems = this.ROUTESADMIN;
    } else {
      // console.log('USER VERIF', this.currentUser);
      this.menuItems = this.ROUTESADMIN;
    }

    // this.toggleSidebar();
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === 'Side') {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === 'Top') { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === 'Side') { // Vertical Menu{
      this.menuItems = this.ROUTESADMIN;
    }


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    // console.log(conf);
    this.configService.applyTemplateConfigChange({layout: conf.layout});

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
