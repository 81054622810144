import {Routes} from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'page',
    loadChildren: () => import('../../page/page.module').then(m => m.PageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('../../page/categorie/categorie.module').then(m => m.CategorieModule)
  },
  {
    path: 'organisations',
    loadChildren: () => import('../../page/organisation/organisation.module').then(m => m.OrganisationModule)
  },
  {
    path: 'form-management',
    loadChildren: () => import('../../form-management/form-management.module').then(m => m.FormManagementModule)
  },
  {
    path: 'violation-type',
    loadChildren: () => import('../../violation-type/violation-type.module').then(m => m.ViolationTypeModule)
  },
  {
    path: 'account-management',
    loadChildren: () => import('../../account-management/account-management.module').then(m => m.AccountManagementModule)
  },
  {
    path: 'follow-management',
    loadChildren: () => import('../../follow-management/follow-management.module').then(m => m.FollowManagementModule)
  }
];
