import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthBody} from '../utils/auth-body';
import {KenekankoUtils} from '../utils/kenekanko-utils';
import {Observable} from 'rxjs';
import {IResponse} from '../models/base/i.response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly serviceURL: string;
  readonly httpOptions: any;

  constructor(private http: HttpClient, private router: Router) {
    this.serviceURL = environment.apiUrl + '/auth';
    this.httpOptions = new KenekankoUtils().httpHeaders();
  }

  public login(authBody: AuthBody) {
    return this.http.post(this.serviceURL + '/login', authBody);
  }

  updatePwd(authBody: AuthBody): Observable<any> {
    return this.http.post<any>(this.serviceURL + '/update_pwd', authBody);
  }

  public resetPwd(email: string, verificationCode: string) {
    return this.http.get(`${this.serviceURL}/reset_pwd/${email}/${verificationCode}`);
  }

  public sendVerificationCode(email: string) {
    return this.http.get(this.serviceURL + '/send_verification_code/'+email);
  }

  public isLoggedIn(userId: number, callback) {
    return this.http.get(this.serviceURL + '/auth/loggedIn/' + userId)
      .subscribe(ret => {
        callback(ret['response']);
      });
  }

  forceLogout() {
    localStorage.removeItem('isLoggedin');
    // localStorage.removeItem('app-user');
    this.router.navigate(['/login']);
  }

}
