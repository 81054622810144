<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle style="background: #007bff; color: white !important;" class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{dialogTitle}}</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0">

    <ngx-spinner
      bdColor="rgba(51,51,51,0.8)"
      size="medium"
      color="#006400"
      type="ball-scale-multiple"
    >
      <p style="font-size: 20px; color: white">S'il vous plaît, attendez ...</p>
    </ngx-spinner>

    <form [formGroup]="resetForm">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Ancien mot de passe</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="oldPassword">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>
            Ce champ est obligatoire
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nouveau mot de passe</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPassword">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error>
            Ce champ est obligatoire
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Confirmer le mot de passe</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" formControlName="confirmPassword">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="resetForm.get('confirmPassword').hasError('required')">
            Ce champ est obligatoire
          </mat-error>
          <mat-error *ngIf="!resetForm.get('confirmPassword').hasError('required') &&
                                       resetForm.get('confirmPassword').hasError('passwordsNotMatching')">
            Les mots de passe doivent correspondre
          </mat-error>
        </mat-form-field>
      </div>
    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="center">

    <button (click)="matDialogRef.close()"
            mat-button
            class="mr-8">
      ANNULER
    </button>

    <button mat-button (click)="onResetPassword()"
            class="save-button"
            [disabled]="resetForm.invalid">
      VALIDER
    </button>
  </div>
</div>
