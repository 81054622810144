// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:9090/kenekanko',
  apiUrl:'https://tuwindi.app:8443/kenekanko',

  // apiUrl: 'https://bfa.ypa.mybluehost.me:8443/kenekanko',
  // urlBackend:"http://localhost:9090/kenekanko",
  urlBackend:'https://tuwindi.app:8443/kenekanko',

  // apiUrl: 'http://localhost:9090/kenekanko',
  // urlBackend:"http://localhost:9090/kenekanko",
  errorMessage: 'Une erreur est survenue',
  errorNetworkMessage: 'Echec de connexion au serveur',
  notAuthorizedUserMessage: 'Vous n\'êtes pas autorisé à vous connecter sur cette application'
};
