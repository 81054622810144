import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {ConfigService} from './shared/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  public configMenu: any = {};

  constructor(private router: Router,
              private config: ConfigService) {
  }

  ngOnInit() {
    this.defautlTheme();
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  defautlTheme() {
    let color: any = {code: 'info', class: 'bg-info', active: false, type: 'solid'};
    let conf = this.config.templateConf;
    conf.layout.sidebar.backgroundColor = color.code;
    this.config.applyTemplateConfigChange({layout: conf.layout});
    this.bgImageDisplay();
  }

  bgImageDisplay() {
    let conf = this.config.templateConf;
    conf.layout.sidebar.backgroundImage = false;
    this.config.applyTemplateConfigChange({layout: conf.layout});
  }


}
