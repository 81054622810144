import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {User} from '../../models/entities/user.model';
import {KenekankoUtils} from '../../utils/kenekanko-utils';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private router: Router)
    {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let newReq = req.clone();
      const user = localStorage.getItem("app-token");
        if ( user )
        {
          let token: string = new KenekankoUtils().getToken();
            newReq = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if ( error instanceof HttpErrorResponse && error.status === 401 )
                {
                    // Sign out
                    // this._authService.signOut();
                  this.router.navigate(['login']);

                    // Reload the app
                    // location.reload();
                }

                return throwError(error);
            })
        );
    }
}
